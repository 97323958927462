import './about.scss'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/system';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
}));


export default function About() {
    return (
        <div className='about' id='about'>
            <Container maxWidth="sm" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ width: '100%' }}>
                    <Stack spacing={2}>
                        <Item> <h2>👩🏻 About me</h2></Item>
                        <Item>
                            <p>Hello! My name is Ruth and I love creating things.
                                I first dabbled with coding during an internship, where I realised that many daily tasks had the potential to be automated.
                                With my little coding knowledge, I searched the web for solutions and put together VBA scripts to automate some workflows.
                                The joy of building something that works was indescribable. Seeing the endless possibilities of the digital space, I decided to pursue this domain as a career.
                            </p>
                            <br />
                            <p>
                                Fast-forward to today, I have had the privilege of working in various companies to implement digital solutions.
                                I am currently a full stack developer working projects that improve developer experience.
                            </p>
                            <br />
                            Here are some technologies I have been working with lately: <b>
                            Flask,
                            JavaScript,
                            React,
                            RPA,
                            Cloud (AWS, GCP)</b>.
                        </Item>
                    </Stack>
                </Box>
            </Container>
        </div>
    )
}