import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';


import OpenInNewIcon from '@mui/icons-material/OpenInNew';




import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';


import "./works.scss";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    ...theme.typography.body2,
    color: 'theme.palette.text.secondary',
}));

const works = [

    {
        id: 0,
        type: 'GitHub Actions',
        title: 'Markdown Image Alt-text Checker',
        description: `This project was selected as a winning solution for the Microsoft Code; Without Barriers Hackathon 2023. It is a GitHub Action that checks markdown (.md) files for images and flags out missing inline alt-text. Optional usage with the Microsoft Azure Cognitive Services integration to get suggestions for the missing alt-text.`,
        stack: 'NodeJS, Azure Cognitive Services',
        img: '',
        source: 'https://github.com/marketplace/actions/markdown-image-alt-text-checker',
    },
    {
        id: 1,
        type: 'Web App',
        title: 'StorywrAIter',
        description: `A web application that uses generative AI for children storytelling in Bahasa Melayu. As 1 out of 2 engineers who worked on the product, I was responsible for the front and backend development as well as occasional deployment. The frontend is responsive, and was built using React and MaterialUI library, and backend was built using Flask and MySQL. The team involved was also interviewed for a feature in the local press.`,
        stack: 'React, Flask, MySQL, GCP',
        img: '',
        source: 'https://www.storywraiter.com',
    },
    {
        id: 2,
        type: 'Telegram Bot',
        title: '@spampolice_bot',
        description: `A telegram bot built to help identify spam messages in group chats almost instantaneously, and allow non-admin participants to delete the spam messages and ban the user.`,
        stack: 'Telegram API, AWS Lambda & API Gateway',
        img: '',
        source: 'https://github.com/ruthtxh/spam-police-telegram-bot',
    },
    {
        id: 3,
        type: 'VSTO Office Add-ins',
        title: 'TagUI Add-ins',
        description:
            'With over 1k downloads, this Microsoft Word and Excel Add-ins for TagUI helps to improve user experience by providing a user interface for the CLI tool. ',
        stack: 'C#, .Net',
        source: 'https://github.com/kelaberetiv/TagUI/tree/master/src/office'
    },
    {
        id: 4,
        type: 'Web App',
        title: 'e-Namecards',
        description: `An internal web portal to generate e-namecards with QR code and vCard information, for an environmentally friendly way for people to save business contact information when meeting someone new. I conceptualised and developed this idea from scratch.`,
        img: '',
        stack: 'JavaScript, Azure MSAL (SSO), AWS S3',
    },
    {
        id: 5,
        type: 'Web App',
        title: 'Inventory System',
        description: `An inventory system built with complex identity and access management. This was a group project and I was the technical lead responsible for the overall design, integration, as well as development for specific use cases.`,
        stack: '.NET MVC, MSSQL, Bootstrap',
        img: '',
        source: 'https://drive.google.com/file/d/1T9kDmAGa7Ttkkp3zKCkUbgh6FAlzJ2ak/view',
    },
];







export default function Works() {
    const [open, setOpen] = React.useState(false);
    const [detail, setDetail] = React.useState(0);

    function handleClickOpen(id) {
        setOpen(true);
        setDetail(id);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div className="works" id="works">
            <Container maxWidth="sm" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ width: '100%' }}>
                    <Stack spacing={2}>
                        <Item>   <h2>🚀 Some stuff I've built</h2></Item>
                        <Item>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {works.map((work, index,) => (
                                        <Grid item xs={2} sm={4} md={4} key={index}>
                                            <CardActionArea onClick={() => handleClickOpen(work.id)}>
                                                <Card sx={{ backgroundColor: '#F0EBE3', height: '150px' }} >
                                                    <CardContent>
                                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                            {work.type}
                                                        </Typography>
                                                        <Typography>
                                                            {work.title}
                                                        </Typography>
                                                        <br />
                                                        <Typography variant="overline" sx={{ mb: 0.5, lineHeight: 'normal' }} color="text.secondary">
                                                            {work.stack}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </CardActionArea>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Item>

                    </Stack>
                </Box>
            </Container>
            <div>
                {works.filter(x => x.id == detail).map((work, index,) => (
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            {work.title}
                        </BootstrapDialogTitle>
                        <DialogContent >
                            <Typography gutterBottom>
                                {work.description}
                            </Typography>

                            <Typography color="#A27B5C" fontSize="80%" gutterBottom>
                                <i>{work.stack}</i>
                            </Typography>
                            <br />
                            <img src={`/assets/${work.id}.png`} className="img" alt="image" />
                            {work.source != null ? <IconButton onClick={() => window.open(work.source, '_blank', 'noopener,noreferrer')}><OpenInNewIcon /></IconButton>
                                : null}
                        </DialogContent>
                    </BootstrapDialog>
                ))}
            </div>
        </div >
    );
}