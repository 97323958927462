import './contact.scss'
import { Container } from '@mui/system';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
}));

export default function Contact() {
    return (
        <div className='contact' id='contact'>
            <Container maxWidth="sm" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <Box sx={{ width: '100%' }}>
                    <Stack spacing={2} className="light">
                        <Item>  <h2>👋🏻 Let's connect</h2></Item>
                        <Item>   <span >I am always open to technical collaborations, feel free to drop me a message!</span></Item>
                        <Item> <Button href="mailto:ruthtoh@live.com.sg" variant="contained">Say hello</Button></Item>
                        <Item>
                            <IconButton href="mailto:ruthtoh@live.com.sg"><EmailIcon /></IconButton>
                            <IconButton href="https://github.com/ruthtxh"><GitHubIcon /></IconButton>
                            <IconButton href="https://www.linkedin.com/in/ruthtxh/"><LinkedInIcon /> </IconButton>
                        </Item>
                        <Item style={{ marginTop: 45,textAlign: "center"}}>
                            <div><span style={{ color: "#2C3639" }}>© </span>
                                <span style={{ color: "#7d9d9c" }}>❷ </span>
                                <span style={{ color: "#A27B5C" }}>⓿ </span>
                                <span style={{ color: "#e4dccf" }}>❷ </span>
                                <span style={{ color: "#F0EBE3" }}>❸</span>
                            </div>
                        </Item>
                    </Stack>
                </Box>

            </Container>

        </div >
    )
}