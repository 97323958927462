import './app.scss'

import Topbar from "./components/topbar/Topbar.tsx";
import Jobs from "./components/jobs/Jobs.tsx"
import Home from "./components/home/Home";
import About from "./components/about/About";
import Works from "./components/works/Works.tsx";
import Contact from "./components/contact/Contact";

function App() {
  return (
    <div className="app">
      <Topbar />
      <div className="sections">
        <Home />
        <About />
        <Jobs />
        <Works />
        <Contact />
      </div>
    </div>
  );
}

export default App;
