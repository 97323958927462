import * as React from 'react';
import "./jobs.scss";


import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';



import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
}));

const steps = [
    {
        company: 'AI Singapore',
        title: 'Full Stack Developer',
        year: 'Jan 2021 - Present',
        description: [
            'Build a generative AI web application using ReactJS, Flask and MySQL',
            'Develop and integrate the frontend of a Federated Learning as a Service web application using VueJS',
            'Lead engineer maintaining the open source RPA software TagUI',
            'Train users on TagUI/RPA through learning website built using ReactJS and modular video courses',
            'Develop productivity tools for TagUI with Microsoft Office VSTO Add-ins',
            'Improve application life cycle by implementing CI/CD pipelines with GitHub Actions',],
    },
    {
        company: 'Scoot',
        title: 'IT Analyst',
        year: 'Sep 2019 - Oct 2020',
        description:
            ['Conceptualised and developed internal eNamecard portal (Stack: HTML, JS, CSS, Azure MSAL (SSO), AWS S3)',
                'Led the set-up of in-house RPA infrastructure (Automation Anywhere)',
                'Supported RPA related functions as BA/Developer/Infrastructure Engineer',
                'Independently solutioned RPA solutions for 3 use cases using Automation Anywhere through proper SDLC from requirement gathering, designing, developing, testing, deploying and documentation',
                'Co-managed various IT projects by liaising with external vendors and internal stakeholders',]
    },
    {
        company: 'DNV',
        year: 'Mar 2019 - Jul 2019',
        title: 'Software Engineering Intern',
        description: ['Developed front-end web applications using Angular framework with Leaflet and Three.js libraries for digital twin web application',
            'Independently delivered Proof of Concept prototype to client using ArcGIS Web AppBuilder in 15 working days',
            'Designed wireframes of web applications using UXPin based on relevant UI/UX principles',
            'Researched on feasibility of Azure IoT Edge Solutions for smart cities',
        ],
    },
    {
        company: 'SP Group',
        year: 'May 2016 - Dec 2016',
        title: 'Gas Network Planning Intern',
        description: ['Analysed fluid flow and ran feasibility studies on Singapore’s gas network using Synergi Gas',
            'Utilised Synergi Gas to develop low-pressure distribution network models',
            'Automated pre-consultation replies using VBA',
            'Assisted in Singapore’s Ten-Year Gas Transmission Network Development Plan'],
    },
];



const Jobs = () => {


    const [activeStep, setActiveStep] = React.useState(0);




    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };








    return (
        <div className='jobs' id='experience'>
            <Container maxWidth="sm" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ width: '100%' }}>
                    <Stack spacing={0}>
                        <Item>  <h2>💼 Where I've worked </h2></Item>
                        <Item>
                            <Box>
                                <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index,) => (
                                        <Step key={step.company} sx={{ fontSize: 'bold' }}>
                                            <StepLabel
                                                onClick={handleStep(index)}
                                                optional={
                                                    (
                                                        <Typography variant="caption">{step.year}</Typography>
                                                    )
                                                }


                                            >
                                                {step.title} @   <b>{step.company}</b>
                                            </StepLabel>
                                            <StepContent style={{ maxHeight: '220px', overflowY: 'auto', marginRight: '0', padding: '0' }} >
                                                <Typography variant="body2">

                                                    <ul className="pointer">
                                                        {step.description.map((point, index,) => (
                                                            <li>{point}</li>))
                                                        }
                                                    </ul>
                                                </Typography>

                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>

                            </Box>
                        </Item>
                    </Stack>
                </Box>

            </Container >

        </div >
    );
};
export default Jobs;