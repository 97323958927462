import "./home.scss";
import { useEffect, useState } from "react";
import { Container } from '@mui/system';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Link from '@mui/material/Link';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    color: theme.palette.text.secondary,
}));


const jobs = [
    {
        id: '0',
        title: 'Full Stack Developer',
    },
    {
        id: '1',
        title: 'RPA Engineer',

    },
    {
        id: '2',
        title: 'Tech Trainer',
    },

];



export default function Home() {
    const [job, setJob] = useState(0);
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        if (job < jobs.length - 1) setJob(job => job + 1);
        else setJob(0);
    }, [seconds]);

    return (
        <div className="home" id="home">

            <Container sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ width: '100%' }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div className="wrapper">
                            <Item>   <h2>Hello I'm</h2></Item>
                            <Item>     <h1>Ruth Toh</h1></Item>
                            <Item>   <h3>
                                and I'm a <span> {jobs[job].title}</span>
                            </h3></Item>
                            <Item>
                            </Item>
                        </div>
                        <Link
                            href="#about"
                            underline="none"
                            variant="body2"
                        >
                            <KeyboardArrowDownIcon fontSize="large" />
                        </Link>
                    </Stack>
                </Box>
            </Container>




        </div>
    );
}